import {
  Button,
  Card,
  CurrencyInput,
  formatDate,
  Hint,
  Modal,
  Select,
  StickyFooterButtons,
  useLoading,
  ValueOpt,
} from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  billingTransaction,
  getLastChargeForPurchaseEntry,
  updatePurchaseOpportunityEntry,
} from "../../api/RequesTixApi";
import { PurchaseOpportunityStatusIds } from "../../constants/PurchaseOpportunityConstants";
import { AdminPurchaseOpportunityEntryDTO, PurchaseOpportunityStatus } from "../../types/PurchaseOpportunity";
import { TransactionHistory } from "../../types/Transaction";
import { BillingTransactionDTO } from "../../types/UserBilling";
import CreditCardDisplay from "../payment/CreditCardDisplay";

const Container = styled.div`
  width: 600px;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

type AdminPurchaseOpportunityGameFormProps = {
  entry: AdminPurchaseOpportunityEntryDTO;
  statusOpts: ValueOpt<PurchaseOpportunityStatus>[];
  refresh: () => Promise<any>;
  formChanged: boolean;
};

type ChargeModalProps = {
  isCharge: boolean;
  entry: AdminPurchaseOpportunityEntryDTO;
  open: boolean;
  setOpen: (value: boolean) => void;
  refresh: () => Promise<any>;
};

const ChargeModal: React.FC<ChargeModalProps> = ({ isCharge, entry, open, setOpen, refresh }) => {
  const { setLoading } = useLoading();
  const [amount, setAmount] = useState<number>(0);

  const close = () => {
    setOpen(false);
  };

  const sendTransaction = async () => {
    try {
      setLoading(true);
      const txDTO: BillingTransactionDTO = {
        purchaseEntryId: entry.purchaseOpportunityEntryId,
        userBillingId: entry.userBilling.userBillingId,
        amount: amount,
        charge: isCharge,
        paidByBoc: true,
      };
      await billingTransaction(txDTO);
      close();
      await refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPreviousCharge = async (entryId: number) => {
    try {
      setLoading(true);
      const res: TransactionHistory = await getLastChargeForPurchaseEntry(entryId);
      setAmount(res.amount);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isCharge) {
      void fetchPreviousCharge(entry.purchaseOpportunityEntryId);
    }
  }, [isCharge, entry]);

  return (
    <Modal show={open}>
      <Modal.Header close={close}>{isCharge ? "Charge" : "Refund"} Card</Modal.Header>
      <Modal.Body>
        <CurrencyInput
          id="chargeAmount"
          value={amount}
          onChange={setAmount}
          label={isCharge ? "Charge Amount" : "Refund Amount"}
          disabled={!isCharge}
          required
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button className="me-2" variant="primary" onClick={sendTransaction} disabled={amount <= 0}>
            {isCharge ? "Charge" : "Refund"}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const AdminPurchaseOpportunityGameForm: React.FC<AdminPurchaseOpportunityGameFormProps> = ({
  entry,
  statusOpts,
  refresh,
  formChanged,
}) => {
  const { setLoading } = useLoading();
  const [hasChanged, setHasChange] = useState<boolean>(false);
  const [localEntry, setLocalEntry] = useState<AdminPurchaseOpportunityEntryDTO>();
  const [chargeOpen, setChargeOpen] = useState<boolean>(false);

  const changeStatus = (value: ValueOpt<PurchaseOpportunityStatus>) => {
    setHasChange(true);
    setLocalEntry({
      ...localEntry,
      purchaseOpportunityStatus: value.value,
    });
  };

  const saveEntry = async () => {
    try {
      setLoading(true);
      await updatePurchaseOpportunityEntry(localEntry);
      await refresh();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLocalEntry(entry);
  }, [entry]);

  return (
    <Container>
      <Card>
        <Card.Header>Game {entry.gameNumber}</Card.Header>
        <Card.Body>
          {!!localEntry && (
            <>
              <ChargeModal
                isCharge={
                  localEntry.purchaseOpportunityStatus.purchaseOpportunityStatusId ===
                  PurchaseOpportunityStatusIds.SELECTED
                }
                entry={entry}
                refresh={refresh}
                open={chargeOpen}
                setOpen={setChargeOpen}
              />
              <Select
                id={`${entry.purchaseOpportunityEntryId}-game-status`}
                options={statusOpts}
                value={statusOpts.find(
                  (opt: ValueOpt<PurchaseOpportunityStatus>): boolean =>
                    opt.value.purchaseOpportunityStatusId ===
                    localEntry.purchaseOpportunityStatus.purchaseOpportunityStatusId
                )}
                label="Entry Status"
                required
                onChange={changeStatus}
                gutterBottom
              />
              {[
                PurchaseOpportunityStatusIds.SELECTED,
                PurchaseOpportunityStatusIds.CHARGED,
                PurchaseOpportunityStatusIds.REFUNDED,
              ].includes(localEntry.purchaseOpportunityStatus.purchaseOpportunityStatusId) && (
                <div>
                  <div className="d-flex">
                    <CreditCardDisplay card={localEntry.userBilling} />
                    {!!entry.lastChargedDate && (
                      <div className="ms-1">
                        <Hint>Charged on: {formatDate(entry.lastChargedDate)}</Hint>
                      </div>
                    )}
                  </div>

                  <Button
                    className="mt-1"
                    variant="secondary"
                    onClick={() => setChargeOpen(true)}
                    disabled={formChanged}
                  >
                    {localEntry.purchaseOpportunityStatus.purchaseOpportunityStatusId ===
                    PurchaseOpportunityStatusIds.CHARGED
                      ? "Refund"
                      : "Charge"}
                    &nbsp;Card
                  </Button>
                </div>
              )}
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <StickyFooterButtons>
            <Button variant="primary" disabled={!hasChanged} onClick={saveEntry}>
              Save
            </Button>
          </StickyFooterButtons>
        </Card.Footer>
      </Card>
    </Container>
  );
};

export default AdminPurchaseOpportunityGameForm;
